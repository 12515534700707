<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t("menu.survey") }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      :headers="headers"
      :items="surveyTemplateList"
      class="agTable"
      :loading="loading"
      :search="search"
      sort-by="id"
      :no-results-text="$t('general.ui.not_search_found')"
      :no-data-text="$t('general.ui.not_data_found')"
      :loading-text="$t('general.notification.loadData')"
      :footer-props="{
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-row class="d-flex justify-space-between align-baseline">
            <v-col class="pl-0" lg="4" md="4" sm="6" xl="4" cols="6">
              <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                dense
                filled
                rounded
                color="primary"
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('general.titles.filter')"
                :clearable="true"
                single-line
                hide-details
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col lg="4" md="4" sm="4" xl="4" cols="4" class="text-end">
              <v-btn
                small
                tile
                :elevation="0"
                color="gray"
                style="border-radius: 1.5em;"
                @click="addItem()"
                v-if="check([{ domain: 'surveyAdm', permisions: ['Write'] }])"
              >
                <v-icon small color="neuter" left>mdi-plus</v-icon>
                {{ $t("general.buttons.new") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.surveyStateTypeName="{ item }">
        <span v-if="item.surveyStateType === 'EDITING'">
          <v-chip small color="disabled" text-color="t-bw-disabled">
            {{ item.surveyStateTypeName }}
          </v-chip>
        </span>
        <span v-if="item.surveyStateType === 'PUBLISHED'">
          <v-chip small>
            {{ item.surveyStateTypeName }}
          </v-chip>
        </span>
      </template>
      <template v-slot:item.blockedBy="{ item }">
        <v-chip small v-if="item.isBlocked" color="disabled" text-color="t-bw-disabled">
          <v-icon
            color="neuter"
            small
            left
          >
            {{ item.isBlocked ? 'mdi-lock-outline' : 'mdi-lock-open-variant-outline' }}
          </v-icon>
          {{ item.blockedBy.name.toUpperCase()}}
        </v-chip>
        <span v-else>
          -
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end align-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                v-if="
                item.surveyStateType === 'EDITING' &&
                  check([{ domain: 'surveyAdm', permisions: ['Update'] }])
              "
                color="neuter"
                class="mr-2"
                small
                @click="publish(item)"
              >
                mdi-book-check-outline
              </v-icon>
            </template>
            <span>
            {{ $t("general.buttons.publish") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.survey") }}
            </span>
          </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                v-if="
                item.surveyStateType === 'EDITING' &&
                  check([{ domain: 'surveyAdm', permisions: ['Update'] }])
              "
                color="neuter"
                small
                @click="editItem(item)"
            >
              mdi-square-edit-outline
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.edit") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.survey") }}
            </span>
          </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                v-if="item.surveyStateType === 'PUBLISHED' && check([{ domain: 'surveyAdm', permisions: ['Update'] }])"
                color="neuter"
                small
                :disabled="(item.blockedBy && item.blockedBy.id !== profile.id) && !checkAdmin()"
                @click="blockItem(item)"
            >
                {{ !item.isBlocked ? 'mdi-lock-outline' : 'mdi-lock-open-variant-outline' }}
            </v-icon>
          </template>
          <span>
            {{ item.isBlocked ? $t("general.buttons.unlock") : $t("general.buttons.lock") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.survey") }}
            </span>
          </span>
          </v-tooltip>
          <ConfirmDialog
            v-if="check([{ domain: 'surveyAdm', permisions: ['Update'] }])"
            :item="item"
            :is-icon="true"
            :is-tooltip="true"
            :is-btn="false"
            :title="$t('general.titles.information')"
            :dialog-text="$t('survey.notification.duplicateSurvey')"
            :tp-text="$t('general.buttons.duplicate')"
            :ts-text="$t('menu.survey')"
            :tooltip="true"
            btn-title=""
            btn-icon="mdi-content-copy"
            color="neuter"
            action="initiateSurveyDuplication"
            styles="mx-2"
          />
          <ConfirmDialog
            v-if="item.surveyStateType === 'PUBLISHED' && check([{ domain: 'surveyAdm', permisions: ['Update'] }])"
            :item="item"
            :is-icon="true"
            :is-tooltip="true"
            :is-btn="false"
            :title="$t('general.titles.information')"
            :dialog-text="$t('survey.notification.versioningSurvey')"
            :tp-text="$t('general.buttons.versioning')"
            :ts-text="$t('menu.survey')"
            :tooltip="true"
            :valid="item.isBlocked"
            btn-title=""
            btn-icon="mdi-content-duplicate"
            color="neuter"
            action="initiateSurveyVersioning"
            styles="mr-2"
          />
          <ConfirmDialog
            v-if="
                (item.surveyStateType === 'EDITING' ||
                  item.surveyStateType === 'PUBLISHED') &&
                  check([{ domain: 'surveyAdm', permisions: ['Delete'] }])
              "
            :item="item"
            :is-icon="true"
            :is-tooltip="true"
            :is-btn="false"
            :title="$t('general.titles.alert')"
            :dialog-text="$t('general.notification.deleteItems')"
            :tp-text="$t('general.buttons.delete')"
            :ts-text="$t('menu.survey')"
            :tooltip="true"
            :valid="item.isBlocked"
            btn-title=""
            btn-icon="mdi-trash-can-outline"
            action="deleteItem"
            :frBtnProps="{
            small: true,
            color: 'error',
            textColor: 't-bw-error--text',
            elevation: 0,
            text: false
          }"
          />
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDuplicateVisible" max-width="640px" persistent>
      <v-card>
        <v-card-title>{{ $t("survey.titles.duplicatingTemplate") }}</v-card-title>
        <v-card-subtitle class="d-flex justify-start align-center">
          {{ currentStatus }}
          <v-progress-circular class="mx-2" v-if="isFetchingSurvey" dark size="12" width="2" indeterminate />
        </v-card-subtitle>
        <v-card-text>
          <v-progress-linear
            :value="progress"
            color="primary"
            stream
            buffer-value="100"
            height="25"
            style="border-radius: .2em;"
          >
            <template v-slot:default="{ value }">
              <span style="color: #F2F2F2;">{{ currentStep }} / {{ totalSteps }} <strong>({{ Math.ceil(value) }}%)</strong></span>
            </template>
          </v-progress-linear>
          <div class="my-1">
            <strong>{{ $t('survey.fields.section') }}: </strong> {{ currentSectionIndex }} <strong>de</strong> {{ totalSections }}
            <div :key="currentSectionStep" class="text-subtitle-1">
              {{ currentSectionStatus }}
            </div>
          </div>

          <div class="my-1">
            <strong>{{ $t('survey.fields.question') }}: </strong> {{ currentQuestionIndex }} <strong>de</strong> {{ totalQuestions }}
            <div :key="currentQuestionStep" class="text-subtitle-1">
              {{ currentQuestionStatus }}
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogVersionVisible" max-width="640px" persistent>
      <v-card>
        <v-card-title>{{ $t("survey.titles.generateVersion") }}</v-card-title>
        <v-card-subtitle class="d-flex justify-start align-center">
          {{ currentStatus }}
          <v-progress-circular class="mx-2" v-if="isFetchingSurvey" dark size="12" width="2" indeterminate />
        </v-card-subtitle>
        <v-card-text>
          <v-progress-linear
            :value="progress"
            color="primary"
            stream
            buffer-value="100"
            height="25"
            style="border-radius: .2em;"
          >
            <template v-slot:default="{ value }">
              <span style="color: #F2F2F2;">{{ currentStep }} / {{ totalSteps }} <strong>({{ Math.ceil(value) }}%)</strong></span>
            </template>
          </v-progress-linear>
          <div class="my-1">
            <strong>{{ $t('survey.fields.section') }}: </strong> {{ currentSectionIndex }} <strong>de</strong> {{ totalSections }}
            <div :key="currentSectionStep" class="text-subtitle-1">
              {{ currentSectionStatus }}
            </div>
          </div>

          <div class="my-1">
            <strong>{{ $t('survey.fields.question') }}: </strong> {{ currentQuestionIndex }} <strong>de</strong> {{ totalQuestions }}
            <div :key="currentQuestionStep" class="text-subtitle-1">
              {{ currentQuestionStatus }}
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Confirm Dialog for Survey Duplication -->
    <v-dialog v-model="dialogNameCheckVisible" max-width="500px" persistent>
      <v-card>
        <v-card-text class="pt-6 pb-0">
          <v-text-field
            color="primary"
            v-model="newSurveyName"
            dense
            :label="$t('survey.titles.enterNewName')"
            outlined
            :error-messages="nameErrorMessages"
            @input="checkSurveyNameUniqueness"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            @click="cancelCheckNameDialog()"
          >
            {{ $t("general.buttons.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="duplicateItem()"
            :disabled="!isNameUnique"
          >
            {{ $t("general.buttons.duplicate") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from "vuex";
import PermisionService from "../../../services/PermisionService";
import AddOrEditSurvey from "./AddOrEditSurvey";
import ConfirmDialog from "../../../components/ConfirmDialog";
import PermissionService from "@/services/PermisionService"

export default {
  name: "Survey",
  components: {
    AddOrEditSurvey,
    ConfirmDialog,
  },
  data: () => ({
    expanded: [],
    profile: null,
    survey: [],
    search: "",
    title: "Información",
    dialogText: "Añada un texto a este dialogo",
    deletingItem: {},
    editingItem: {},
    confirmAction: null,
    dialogDuplicateVisible: false,
    dialogVersionVisible: false,
    progress: 0,
    currentStatus: '',
    currentSectionStatus: '',
    currentQuestionStatus: '',
    totalSteps: 0,
    currentStep: 0,
    currentQuestionStep: 0,
    currentSectionStep: 0,
    currentSectionIndex: 0,
    currentQuestionIndex: 0,
    totalSections: 0,
    totalQuestions: 0,
    isFetchingSurvey: false,
    dialogNameCheckVisible: false,
    newSurveyName: '',
    surveySelected: null,
    isNameUnique: false,
    nameErrorMessages: [],
  }),

  mounted() {
    this.$root.$on('deleteItem', (item) => {
      this.deleteItem(item)
    })

    this.$root.$on('initiateSurveyDuplication', (item) => {
      this.initiateSurveyDuplication(item)
    })

    this.$root.$on('initiateSurveyVersioning', (item) => {
      this.initiateSurveyVersioning(item)
    })

    this.$root.$on('changePublishStage', (item) => {
      this.changePublishStage(item)
    })
  },

  computed: {
    ...mapGetters({
      loading: "survey/loading",
      requireAndMaxRules: "general/requireAndMaxRules",
      requireRules: "general/requireRules",
      surveyEdit: "survey/surveyEdit",
      surveyTemplateList: "survey/surveyTemplateList",
      error: "survey/error",
    }),
    headers: () => [
      { text: i18n.t("survey.fields.name"), value: "name" },
      { text: i18n.t("survey.fields.state"), value: "surveyStateTypeName" },
      { text: i18n.t("survey.fields.blockedBy"), value: "blockedBy" },
      {
        text: i18n.t("general.fields.action"),
        value: "action",
        width: 120,
        sortable: false,
        align: "center",
      },
    ],
  },
  async created() {
    this.profile = JSON.parse(localStorage.getItem("profile"));
    await this.getSurveyTemplate();
  },
  methods: {
    ...mapActions({
      fetchSurveyById: "survey/fetchSurveyById",
      blockSurveyTemplate: "survey/blockSurveyTemplate",
      saveSurvey: "survey/saveSurvey",
      saveSection: "survey/saveSection",
      saveQuestion: "survey/saveQuestion",
      fetchListSurveyTemplate: "survey/fetchListSurveyTemplate",
      deleteSurvey: "survey/deleteSurvey",
      change_state: "survey/change_state",
      errorOFF: "survey/errorOFF",
    }),
    // Get Survey by Company
    async getSurveyTemplate() {
      const filter = {
        surveyStateType: ["PUBLISHED", "EDITING"],
        checkAccess: false
      };
      await this.fetchListSurveyTemplate([filter, this.$toast]);
    },
    // Add new Survey
    addItem() {
      localStorage.removeItem("survey");
      this.$router.push({ name: "surveyAdd" });
    },
    // Edit new Survey
    editItem(item) {
      localStorage.setItem("survey", JSON.stringify(item.id));
      localStorage.setItem("sameSession", JSON.stringify(false));

      this.$router.push({ name: "surveyAdd" });
    },
    // Edit new Survey
    async blockItem(item) {
      await this.blockSurveyTemplate([item.id, this.$toast]);
      await this.getSurveyTemplate();
    },
    async handleDuplicateSurvey(isVersioning = false) {
      try {
        const original = Object.assign({}, this.surveyEdit);
        const newSurvey = Object.assign({}, this.surveyEdit);
        delete newSurvey.id;
        if (!isVersioning) delete newSurvey.integrityId;
        newSurvey.name = this.newSurveyName;

        this.totalSteps = original.questionSectionList.reduce((acc, section) => acc + section.questions.length + 1, 1);
        this.totalSections = original.questionSectionList.length;
        this.totalQuestions = 0;
        this.currentStep = 0;
        this.currentSectionIndex = 0;
        this.currentQuestionIndex = 0;

        const createdSurvey = await this.saveSurvey([newSurvey, -1, null, null, this.$toast, false]).catch(e => {
          if (!isVersioning)
            this.dialogDuplicateVisible = false;
          else
            this.dialogVersionVisible = false;

          console.error('Error duplicating survey:', e);
          return;
        });

        if (!createdSurvey) {
          throw new Error('Survey creation failed');
        }

        const parentQuestionsMap = new Map();

        // Primera pasada: duplicar todas las preguntas sin modificar las referencias
        for (const section of original.questionSectionList) {
          this.currentSectionIndex++;
          this.totalQuestions = section.questions.length;
          this.currentQuestionIndex = 0;

          const newSection = Object.assign({}, section);
          delete newSection.id;
          newSection.surveyId = createdSurvey.id;
          newSection.questions = [];
          newSection.responsables = [...section.responsables];

          this.currentSectionStatus = `${i18n.t('survey.titles.duplicatingSurvey')} ${section.name}`;
          this.currentStep++;
          this.currentSectionStep++;
          this.progress = (this.currentStep / this.totalSteps) * 100;

          const createdSection = await this.saveSection([newSection, -1, this.$toast, false]).catch(e => {
            if (!isVersioning)
              this.dialogDuplicateVisible = false;
            else
              this.dialogVersionVisible = false;

            console.error('Error duplicating section:', e);
            return;
          });

          if (!createdSection) {
            throw new Error('Section creation failed');
          }

          for (const question of section.questions) {
            this.currentQuestionIndex++;

            const newQuestion = _.cloneDeep(question);
            delete newQuestion.id;
            newQuestion.questionSectionId = createdSection.id;

            this.currentQuestionStatus = `${i18n.t('survey.titles.duplicatingQuestion')} ${question.question}`;
            this.currentStep++;
            this.currentQuestionStep++;
            this.progress = (this.currentStep / this.totalSteps) * 100;

            const createdQuestion = await this.saveQuestion([{ ...newQuestion, company: { id: this.profile.company_id } }, -1, this.$toast, false]).catch(e => {
              if (!isVersioning)
                this.dialogDuplicateVisible = false;
              else
                this.dialogVersionVisible = false;

              console.error('Error duplicating question:', e);
              return;
            });

            if (createdQuestion) {
              parentQuestionsMap.set(question.id, createdQuestion.id);
            } else {
              throw new Error('Question creation failed');
            }
          }
        }

        await this.fetchSurveyById([createdSurvey.id, this.$toast]).catch(e => {
          console.error('Error fetching duplicated survey:', e);
          return;
        });

        if (!this.surveyEdit || !this.surveyEdit.questionSectionList) {
          throw new Error('Failed to fetch duplicated survey or survey structure is incorrect');
        }

        // Segunda pasada: actualizar las referencias a las preguntas padre
        for (const section of this.surveyEdit.questionSectionList) {
          for (const question of section.questions) {
            if (question.extraQuestionFatherRef) {
              const oldFatherRefId = question.extraQuestionFatherRef.id;
              const newFatherRefId = parentQuestionsMap.get(oldFatherRefId);
              if (newFatherRefId) {
                // Eliminar el id dentro de possibleAnswer
                if (question.possibleAnswer) {
                  question.possibleAnswer = question.possibleAnswer.map((answer, index) => {
                    return {
                      ...answer,
                      id: index + 1
                    };
                  });
                }
                question.extraQuestionFatherRef.id = newFatherRefId;
                this.currentQuestionStatus = `${i18n.t('survey.titles.updatingQuestionFatherRef')}: ${question.question}`;
                await this.saveQuestion([{ ...question, company: { id: this.profile.company_id } }, question.id, this.$toast, false]).catch(e => {
                  console.error('Error updating question with new father reference:', e);
                  return;
                });
              }
            }
          }
        }

        if (!isVersioning)
          this.dialogDuplicateVisible = false;
        else
          this.dialogVersionVisible = false;

        this.clearHandleSurveyForm();
        this.surveySelected = null;
        this.$store.commit("survey/SET_SURVEY_BY_ID", null);

        return createdSurvey.id;
      } catch (error) {
        console.error('Error handling survey duplication:', error);
        this.dialogDuplicateVisible = false;
        if (isVersioning) this.dialogVersionVisible = false;
      }
    },
    clearHandleSurveyForm() {
      this.progress = 0
      this.currentStatus = ''
      this.currentSectionStatus = ''
      this.currentQuestionStatus = ''
      this.totalSteps = 0
      this.currentStep = 0
      this.currentQuestionStep = 0
      this.currentSectionStep = 0
      this.currentSectionIndex = 0
      this.currentQuestionIndex = 0
      this.totalSections = 0
      this.totalQuestions = 0
      this.isFetchingSurvey = false
    },
    checkSurveyNameUniqueness() {
      const exists = this.surveyTemplateList.some(survey => survey.name === this.newSurveyName);
      this.isNameUnique = !exists;
      this.nameErrorMessages = exists ? [i18n.t('survey.titles.duplicatedSurveyName')] : [];
      this.dialogNameCheckVisible = true;
    },

    initiateSurveyDuplication(item) {
      this.surveySelected = {...item}
      this.newSurveyName = `${item.name}`;
      this.checkSurveyNameUniqueness();
    },

    initiateSurveyVersioning(item) {
      this.surveySelected = {...item}
      this.newSurveyName = `${item.name}`;
      this.versioningItem();
    },

    cancelCheckNameDialog() {
      this.dialogNameCheckVisible = false
      this.nameErrorMessages = []
      this.newSurveyName = ''
      this.isNameUnique = false
    },

    async duplicateItem() {
      this.dialogNameCheckVisible = false;
      this.dialogDuplicateVisible = true;
      this.isFetchingSurvey = true;
      this.currentStatus = `${this.$t('survey.titles.fetchingTemplate')} ${this.surveySelected.name}`;

      await this.fetchSurveyById([this.surveySelected.id, this.$toast]).finally(() => {
        this.isFetchingSurvey = false;
      });

      this.currentStatus = `${this.$t('survey.titles.initializedProcess')} ${this.surveySelected.name}`;
      await this.handleDuplicateSurvey(false);

      await this.getSurveyTemplate();
    },

    async versioningItem() {
      this.dialogVersionVisible = true;
      this.isFetchingSurvey = true;
      this.currentStatus = `${this.$t('survey.titles.fetchingTemplate')} ${this.surveySelected.name}`;

      await this.fetchSurveyById([this.surveySelected.id, this.$toast]).finally(() => {
        this.isFetchingSurvey = false;
      });

      this.currentStatus = `${this.$t('survey.titles.initializedProcess')} ${this.surveySelected.name}`;
      const surveyId = await this.handleDuplicateSurvey(true);
      console.log(surveyId)

      this.editItem({ id: surveyId });
    },

    // Delete Survey
    async deleteItem(item) {
      await this.deleteSurvey([item.id, this.$toast])
      await this.getSurveyTemplate();
    },
    // Publish Survey
    async publish(item) {
      const changeStateDTO = {
        surveyStateType: "PUBLISHED",
        surveyId: item.id,
      };
      await this.changeStateSurvey(changeStateDTO, 'save')
      await this.getSurveyTemplate();
    },
    // Change to editing state for edit Survey
    changePublishStage(item) {
      const changeStateDTO = {
        surveyStateType: "EDITING",
        surveyId: item.id,
      };
      this.changeStateSurvey(changeStateDTO, 'save').finally(() => {
        if (!this.error) {
          this.editItem(item);
        } else {
          this.errorOFF();
        }
      });
    },
    // Survey State Change
    async changeStateSurvey(changeStateDTO, action) {
      return await this.change_state([changeStateDTO, this.$toast, action]);
    },
    // Check permition for actions
    check(permisions) {
      return PermisionService.check(permisions);
    },
    // Check permission Admin
    checkAdmin() {
      return PermissionService.checkAdmin()
    },
    // Confirm Dialog Show
    dialogShow(params) {
      this.title = params.title;
      this.dialogText = params.dialogText;
      this.$refs.dialogConfirm.openDialog();
    },
    // Confirm Dialog Close
    dialogClose() {
      this.$refs.dialogConfirm.dialogClose();
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
      this.search =  event.currentTarget.value.toString().replaceAll('"','')
    }
  },

  destroyed() {
    this.$root.$off('deleteItem')
    this.$root.$off('initiateSurveyDuplication')
    this.$root.$off('initiateSurveyVersioning')
    this.$root.$off('changePublishStage')
  }
};
</script>

<style scoped>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: opacity 0.2s ease, transform 0.2s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  opacity: 0;
}
:deep(span.v-chip__content p) {
  padding-top: 3px !important;
  color: rgba(0, 0, 0, 0.38) !important;
  font-weight: 500 !important;
}
</style>
